<template>
  <tr
    :class="[
      'widget-product-main',
      'widget-variant-main',
      isOutOfStock ? 'instabuy-out-of-stock' : '',
    ]"
    v-show="show"
  >
    <td class="widget-product-image widget-variant-image" rowspan="2">
      <Luminous
        v-if="variant.featured_image"
        :thumbnail="variant.featured_image"
        :image="variant.featured_image"
      ></Luminous>
    </td>
    <td class="widget-product-title widget-variant-title" colspan="3">
      <h4>
        <span v-html="highlight(variantTitle, highlightSearchQuery)"></span>
        <p class="widget-product-price">
          <span
            class="widget-compare-at-price"
            v-if="
              variant.compare_at_price && widget.showCompareAtPrice === true
            "
            :data-price="variant.compare_at_price"
            v-html="
              typeof variant.formatted_compare_at_price === 'undefined'
                ? formatMoney(variant.compare_at_price, widget.moneyFormat)
                : variant.formatted_compare_at_price
            "
          ></span>
          <span
            class="widget-current-price"
            :data-price="variant.price"
            v-html="price"
          ></span>
          <span
            class="widget-product-properties"
            v-if="showWeight || showAvailability"
          >
            <span
              class="widget-available"
              v-if="showAvailability"
              v-html="quantityAvailableText"
            ></span>
            <small v-if="showWeight && showAvailability"
              >&nbsp;&bull;&nbsp;</small
            >
            <span class="widget-weight" v-if="showWeight">
              {{ variant.weight_in_unit }}{{ variant.weight_unit }}
            </span>
          </span>
        </p>
      </h4>
      <p v-if="showCartQuantity && quantityInCart > 0">
        {{ quantityInCart }} in cart
      </p>

      <p
        class="widget-product-sku widget-variant-sku"
        v-if="widget.showSku === true && variant.sku"
        v-html="highlight(variant.sku, highlightSearchQuery)"
      ></p>
    </td>
    <ProductQuantityCell
      :value="quantity"
      @input="updateQuantity"
      :hasMultipleVariants="false"
      :quantityInputStyle="quantityInputStyle"
      :variant="variant"
      :product="product"
      :widget="widget"
      :buttonBorderRadius="widget.buttonBorderRadius"
      :showQuantityButtons="widget.showQuantityButtons"
      :showQuantityInput="widget.showQuantityInput"
    ></ProductQuantityCell>
    <ProductButtonCell
      :hasMultipleVariants="false"
      :variant="variant"
      :product="product"
      :widget="widget"
      :quantity="quantity"
      @add-to-cart="$emit('add-to-cart', $event)"
    ></ProductButtonCell>
  </tr>
</template>

<script>
import ProductQuantityCell from "./ProductQuantityCell.vue";
import ProductButtonCell from "./ProductButtonCell.vue";
import formatMoney from "../lib/format-money.js";
import highlight from "../lib/highlight.js";
import Luminous from "./Luminous.vue";
import calculatePrice from "../PricingCalculator.js";

export default {
  props: [
    "show",
    "variant",
    "widget",
    "product",
    "addToCartButtonStyle",
    "unavailableButtonStyle",
    "quantityInputStyle",
    "highlightSearchQuery",
    "pricingRules",
    "customer",
    "cart",
  ],

  components: {
    ProductQuantityCell,
    ProductButtonCell,
    Luminous,
  },

  data: function () {
    return {
      quantity:
        this.widget.buttonPosition !== "row"
          ? 0
          : window.InstaBuy &&
            typeof window.InstaBuy.defaultQuantity != "undefined"
          ? window.InstaBuy.defaultQuantity
          : 1,
    };
  },

  computed: {
    price() {
      if (this.widget.customPricing) {
        return formatMoney(
          calculatePrice(
            this.product,
            this.variant.id,
            this.pricingRules,
            this.customer
          ),
          this.widget.moneyFormat
        );
      } else {
        return typeof this.variant.formatted_price === "undefined"
          ? formatMoney(this.variant.price, this.widget.moneyFormat)
          : this.variant.formatted_price;
      }
    },

    isOutOfStock() {
      return this.variant.inventory_quantity <= 0;
    },

    quantityAvailableText() {
      if (window.InstaBuy && window.InstaBuy.constructQuantityAvailableText) {
        return window.InstaBuy.constructQuantityAvailableText.call(
          this,
          this.variant
        );
      }
      return `${this.quantityAvailable} ${this.widget.availableText}`;
    },

    quantityAvailable() {
      if (
        this.variant.inventory_management === "shopify" &&
        (this.variant.inventory_policy !== "continue" ||
          this.variant.inventory_quantity > 0)
      ) {
        return this.variant.inventory_quantity;
      }
      return null;
    },

    variantTitle() {
      if (window.InstaBuy && window.InstaBuy.constructVariantTitle) {
        return window.InstaBuy.constructVariantTitle.call(this, this.variant);
      } else {
        return this.variant.title;
      }
    },

    showWeight() {
      return this.widget.showWeight === true && this.variant.weight_in_unit;
    },

    showAvailability() {
      return this.widget.showAvailability === true && this.quantityAvailable;
    },

    showCartQuantity() {
      return !!(window.InstaBuy && window.InstaBuy.showCartQuantity);
    },

    quantityInCart() {
      if (this.cart && this.cart.items) {
        var item = this.cart.items.find(
          (item) => item.variant_id === this.variant.id
        );
        if (item) {
          return item.quantity;
        }
      }
      return 0;
    },
  },

  methods: {
    updateQuantity(value) {
      this.quantity = value;
    },

    clear() {
      this.quantity = 0;
    },

    formatMoney,
    highlight,
  },

  created() {
    this.$root.$on("clear-quantity", this.clear);
  },

  beforeDestroy() {
    this.$root.$off("clear-quantity", this.clear);
  },
};
</script>

<style scoped>
</style>
